import { useState } from 'react'
import useCase from '../images/useCase.png'
import readiness from '../images/readiness.png'
import evaluation from '../images/Evaluation_Matrix.png'
import ReactStars from "react-rating-stars-component";

const RelevanceToTheFuture = ({ relevanceList, readinessImageUrl, useCaseImageUrl, isFavorite }) => {
    const [showMoreItems, setShowMoreItems] = useState(false)


    return <div className="flex flex-col p-16">
        <div className="flex items-center justify-center">
            <h1 className="text-3xl font-bold text-primary pb-12">
                Relevance to the Future
            </h1>
        </div>

        {
            relevanceList.slice(0, 3).map((relevanceItem) => {
                return <div className="flex lg:flex-row flex-col items-center lg:items-start pb-2">

                    {isFavorite ? <div className="flex flex-col w-full items-center">
                        <img src={relevanceItem.imageUrl} className='w-auto' alt="trend_image" />
                        {
                            <div className="flex flex-col w-72">
                                <div className="flex flex-row items-center justify-between gap-24 ">
                                    <h5 className='text-xs font-bold'>
                                        Strategic fit
                                    </h5>
                                    <div className="flex flex-row items-end">
                                        {/* <Rating
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            initialValue={relevanceItem.strategicFit}
                                            allowFraction={false}
                                            allowHover={false}
                                            readonly={true}
                                            size={15}
                                        /> */}
                                        <ReactStars
                                            count={3}
                                            edit={false}
                                            value={relevanceItem.strategicFit}
                                            size={18}
                                            activeColor="#ffd700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between ">
                                    <h5 className='text-xs font-bold'>
                                        Pain Point Relevance
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.painPointRelevance}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.painPointRelevance}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between ">
                                    <h5 className='text-xs font-bold'>
                                        Scalability
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.scalability}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.scalability}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between  ">
                                    <h5 className='text-xs font-bold'>
                                        Teck Maturity
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.teckMaturity}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.teckMaturity}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <h5 className='text-xs font-bold'>
                                        Simplicity
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.simplicity}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFract ion={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.simplicity}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex w-56 ">
                                        <h5 className='text-xs font-bold'>
                                            Inno Know-How & Availability
                                        </h5>
                                    </div>
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.innoKnowHowAndAvailability}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                            </div>
                        }
                    </div> : <img src={relevanceItem.imageUrl} className='w-64' alt="trend_image" />}
                    <div className="flex flex-col pb-2 pl-10 justify-between h-full">
                        <div className="flex flex-col">
                            <div className="w-full lg:h-[1px] bg-primary"></div>
                            <h1 className="text-xl font-bold text-dark_blue">
                                {relevanceItem.title}
                            </h1>
                            <h2 className="text-md text-primary ">
                                {relevanceItem.description}
                            </h2>
                        </div>
                        {relevanceItem.sourceLink !== "" ? <div className="flex flex-row">
                            <h2 className='text-gray-400 font-bold'>
                                Sources:
                            </h2>
                            <a href={relevanceItem.sourceLink} className='underline pl-1 text-blue-600'>
                                <h2>
                                    {
                                        relevanceItem.sourceName
                                    }
                                </h2>
                            </a>
                        </div> : null}
                    </div>
                </div>
            })
        }
        {
            showMoreItems && relevanceList.slice(3, 12).map((relevanceItem) => {
                return <div className="flex lg:flex-row flex-col items-center lg:items-start pb-2">
                    <div className="flex flex-col items-center">
                        <img src={relevanceItem.imageUrl} className='h-40' alt="trend_image" />
                        {
                            isFavorite && <div className="flex flex-col w-72">
                                <div className="flex flex-row items-center justify-between gap-24 pb-2">
                                    <h5 className='text-xs font-bold'>
                                        Strategic fit
                                    </h5>
                                    <div className="flex flex-row items-end">
                                        {/* <Rating
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            initialValue={relevanceItem.strategicFit}
                                            allowFraction={false}
                                            allowHover={false}
                                            readonly={true}
                                            size={15}
                                        /> */}
                                        <ReactStars
                                            count={3}
                                            edit={false}
                                            value={relevanceItem.strategicFit}
                                            size={18}
                                            activeColor="#ffd700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row items-center justify-between pb-2">
                                    <h5 className='text-xs font-bold'>
                                        Pain Point Relevance
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.painPointRelevance}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.painPointRelevance}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between  pb-2">
                                    <h5 className='text-xs font-bold'>
                                        Scalability
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.scalability}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.scalability}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between  pb-2">
                                    <h5 className='text-xs font-bold'>
                                        Teck Maturity
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.teckMaturity}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.teckMaturity}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between  pb-2">
                                    <h5 className='text-xs font-bold'>
                                        Simplicity
                                    </h5>
                                    {/* <Rating
                                        initialValue={relevanceItem.simplicity}
                                        SVGstyle={
                                            { 'display': 'inline' }
                                        }
                                        iconsCount={3}
                                        allowFraction={false}
                                        allowHover={false}
                                        readonly={true}
                                        size={15}
                                    /> */}
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.simplicity}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="flex flex-row items-center justify-between  pb-2">
                                    <div className="flex w-56">
                                        <h5 className='text-xs font-bold'>
                                            Inno Know-How & Availability
                                        </h5>
                                    </div>
                                    <ReactStars
                                        count={3}
                                        edit={false}
                                        value={relevanceItem.innoKnowHowAndAvailability}
                                        size={18}
                                        activeColor="#ffd700"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col pb-2 pl-10 justify-between h-full">
                        <div className="flex flex-col">
                            <div className="w-full lg:h-[1px] bg-primary"></div>
                            <h1 className="text-xl font-bold text-dark_blue">
                                {relevanceItem.title}
                            </h1>
                            <h2 className="text-md text-primary ">
                                {relevanceItem.description}
                            </h2>
                        </div>
                        {relevanceItem.sourceLink !== "" ? <div className="flex flex-row">
                            <h2 className='text-gray-400 font-bold'>
                                Sources:
                            </h2>
                            <a href={relevanceItem.sourceLink} className='underline pl-1 text-blue-600'>
                                <h2>
                                    {
                                        relevanceItem.sourceName
                                    }
                                </h2>
                            </a>
                        </div> : null}
                    </div>
                </div>
            })
        }

        {isFavorite && <button onClick={() => {
            setShowMoreItems(!showMoreItems)
        }}>
            {showMoreItems &&
                <div className="flex flex-col">
                    <div className="w-full h-[3px] bg-gray-300"></div>
                    <h1 className='font-bold text-2xl text-[#E55403] py-4'>
                        Click for Less in the Top Trend
                    </h1>
                    <div className="w-full h-[3px] bg-gray-300"></div>
                </div>
            }
            {!showMoreItems && <div className="flex flex-col">
                <div className="w-full h-[3px] bg-gray-300"></div>
                <h1 className='font-bold text-2xl text-[#E55403] py-4'>
                    Click for More in the Top Trend
                </h1>
                <div className="w-full h-[3px] bg-gray-300"></div>
            </div>
            }
        </button>}
        {
            isFavorite && <div className="flex flex-row gap-4">
                <div className="flex flex-col items-center">
                    <h2 className='font-bold text-xl pb-4'>
                        Explanation of Assessment
                    </h2>

                    <a href={evaluation} target="_blank" className='underline pl-1 text-blue-600'>
                        <img src={evaluation} className='w-96' alt="evaluation" />

                    </a>
                </div>
                {readinessImageUrl && <div className="flex flex-col items-center">
                    <h2 className='font-bold text-xl pb-4'>
                        Prioritized Use Cases
                    </h2>

                    <a href={readinessImageUrl} target="_blank" className='underline pl-1 text-blue-600'>
                        <img src={readinessImageUrl} className='w-96' alt="evaluation" />
                    </a>

                </div>}



                {/* {!readinessImageUrl && <div className="flex flex-col items-center">
                    <h2 className='font-bold text-xl pb-4'>
                        Prioritized Use Cases
                    </h2>
                    <a href={useCaseImageUrl}>
                        <img src={useCaseImageUrl} alt="evaluation" />
                    </a>
                </div>} */}
                {useCaseImageUrl && <div className="flex flex-col items-center">
                    <h2 className='font-bold text-xl pb-4'>
                        Proof of Concept
                    </h2>
                    <a href={useCaseImageUrl} target="_blank" className='underline pl-1 text-blue-600'>
                        <img src={useCaseImageUrl} className='w-96' alt="useCase" />
                    </a>
                </div>}
                {/* {!useCaseImageUrl && <div className="flex flex-col items-center">
                    <h2 className='font-bold text-xl pb-4'>
                        Proof of Concept
                    </h2>

                    <img src={useCase} alt="useCase" />

                </div>} */}
            </div>
        }
    </div>
}

export default RelevanceToTheFuture;