import download from '../images/download.png';
import files from '../images/files.png';
import contact from '../images/contact.png';

const LinkButtonsContainer = () => {
  return (
    <div className="flex md:flex-row md:flex-grow justify-around md:justify-end gap-4 mt-4 md:mt-0 pl-[25px] md:pl-0">
      <div>
        <a
          href={'https://aldar.workvivo.com/file/499818'}
          target="_blank"
          rel="noreferrer"
          className="underline pl-1 text-blue-600"
        >
          <div className="flex flex-row items-center mt-4 md:mt-0 md:gap-1">
            <img src={files} className="w-6 h-6" alt="45 trends" />
            <h2 className="hidden md:block">Download 45 Trends</h2>
          </div>
        </a>
      </div>

      <div>
        <a
          href={'https://aldar.workvivo.com/file/499817'}
          target="_blank"
          rel="noreferrer"
          className="underline pl-1 text-blue-600"
        >
          <div className="flex flex-row items-center mt-4 md:mt-0 md:gap-1">
            <img src={download} className="w-6 h-6" alt="top trends" />
            <h2 className="hidden md:block">Download Top Trends</h2>
          </div>
        </a>
      </div>

      <div>
        <a
          href={
            'mailto:gdsspg@aldar.com?subject=Collaborate with SPG team for Emerging Technology Trends&body=Please provide your comments'
          }
          target="_blank"
          rel="noreferrer"
          className="underline pl-1 text-blue-600"
        >
          <div className="flex flex-row items-center mt-4 md:mt-0 md:gap-1">
            <img src={contact} className="w-6 h-6" alt="contact us" />
            <h2 className="hidden md:block">Contact Us</h2>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LinkButtonsContainer;
