import radar from '../images/radar.png';
import React, { useRef, useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ExpandedFilter from './ExpandedFilter';
import useTrendsStore from '../state/trend.state';
import Dialog from '@mui/material/Dialog';
import blockchain from '../images/blockchain.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useSwitchButtonStore from '../state/swich.button.state';

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
  <>
    <div className="lg:flex hidden absolute z-20 top-10 left-10 space-x-5">
      <div
        className="bg-[#2C2A29] shadow p-1"
        onClick={() => {
          resetTransform();
        }}
      >
        <CenterFocusStrongIcon fontSize="large" className="cursor-pointer" style={{ color: 'white' }} />
      </div>
      <div
        className="bg-[#2C2A29] shadow p-1"
        onClick={() => {
          zoomIn();
        }}
      >
        <ZoomInIcon fontSize="large" className="cursor-pointer" style={{ color: 'white' }} />
      </div>
      <div
        className="bg-[#2C2A29] shadow p-1"
        onClick={() => {
          zoomOut();
        }}
      >
        <ZoomOutIcon fontSize="large" className="cursor-pointer" style={{ color: 'white' }} />
      </div>
    </div>
  </>
);

const Radar = () => {
  const transformComponentRef = useRef(null);
  const ref = useRef(null);
  const trendReF = useRef(null);

  const [openDialog, handleDisplay] = useState(false);
  const [dimensionsOfRadar, setDimensionsOfRadar] = useState({
    width: 0,
    height: 0,
  });
  const [clickedTrend, setClickedTrend] = useState({});
  const { trends, setTrends } = useTrendsStore();
  const { switchButton, setSwitchButton } = useSwitchButtonStore();

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const handleResize = () => {
    setDimensionsOfRadar({
      width: ref.current ? ref.current.offsetWidth : 0,
      height: ref.current ? ref.current.offsetHeight : 0,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="flex w-full relative min-w-[700px] max-h-[854px] max-w-[922px]"
      ref={ref}
      id="img-radar-container"
      onLoad={handleResize}
    >
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        minScale={0.95}
        ref={transformComponentRef}
      >
        {(utils) => (
          <React.Fragment>
            <TransformComponent className=" flex relative">
              <img src={radar} alt="radar" className="z-10" />
              {trends.map((trend, index) => {
                return (
                  <div
                    key={index}
                    className={`flex z-20 absolute ${
                      trend.isSelectedForFilter ? 'bg-gray-50' : switchButton > 0 ? `opacity-20` : ``
                    }  items-center justify-start cursor-pointer rounded-md px-1`}
                    ref={trendReF}
                    onClick={() => {
                      setClickedTrend(trend);
                      openDialogBox();
                      // console.log(trendReF.current.offsetLeft / 922);
                      // console.log(trendReF.current.offsetTop / 854);
                    }}
                    style={{
                      top: trend.y * dimensionsOfRadar.height,
                      left: trend.x * dimensionsOfRadar.height,
                    }}
                  >
                    {trend.isFavorite ? (
                      <div
                        className={`flex z-20  
                                                border-l-[6px] border-l-transparent
                                                border-t-[9px] ${
                                                  trend.isSocial ? `border-t-[#cbda00]` : `border-t-[#61b1e8]`
                                                }
                                                border-r-[6px] border-r-transparent items-center justify-center `}
                      ></div>
                    ) : (
                      <div
                        className={`flex z-20 rounded-full w-2 h-2  items-center justify-center `}
                        style={{
                          background: trend.isSocial ?? false ? '#cbda00' : '#61b1e8',
                        }}
                      >
                        <div className="flex rounded-full w-1 h-1 bg-white"></div>
                      </div>
                    )}
                    <h1 className="px-[1px] text-xxs md:text-xs">{trend.title}</h1>
                  </div>
                );
              })}
            </TransformComponent>
            <Controls {...utils} />
            <ExpandedFilter />
          </React.Fragment>
        )}
      </TransformWrapper>
      <Dialog onClose={handleClose} open={openDialog}>
        <div className="flex flex-col items-start">
          <div className="flex relative items-center justify-center">
            {clickedTrend.imageURL && <img src={clickedTrend.imageURL} alt="trend_image" />}
            {!clickedTrend.imageURL && <img src={blockchain} alt="trend_image" />}
            <h1 className="text-4xl absolute text-white font-bold bg-primary px-5">{clickedTrend.title}</h1>
          </div>
          <div className="flex flex-col p-4 items-start">
            <h2 className="text-lg">{clickedTrend.shortDescription}</h2>

            <Link
              to={{
                pathname: `/trend_description/${clickedTrend.id}/${clickedTrend.title}`,
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#000000',
                  margin: '18px 0px',
                }}
                onClick={() => {
                  function myFunction(item, index, arr) {
                    arr[index].isSelectedForFilter = false;
                    setSwitchButton(0);
                  }
                  trends.forEach(myFunction);
                  setTrends(trends);
                }}
              >
                Read Full Article
              </Button>
            </Link>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Radar;
