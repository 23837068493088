import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import jwt from 'jwt-decode';

import { baseURL } from '../constants';
import useAuthStore from '../state/auth.state';

const ProtectedRoute = ({ children }) => {
  const { accessToken } = useAuthStore();

  let location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const [pending, setPending] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchAdminStatus = (accessToken) => {
      const aldarApi = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      let email = null;
      try {
        email = jwt(accessToken).email;
      } catch (e) {
        console.log(e);
      }

      aldarApi
        .get(`/api/users/is_admin/${email}`)
        .then((res) => {
          setIsAdmin(res.data);
          setPending(false);
        })
        .catch((error) => {
          setPending(false);
        });
    };

    if (inProgress === InteractionStatus.None) {
      if (!isAuthenticated) {
        setPending(false);
      }
      if (isAuthenticated && accessToken.length > 1 && pending) {
        fetchAdminStatus(accessToken);
      }
    }
  }, [inProgress, accessToken, pending]);

  if (isAuthenticated && isAdmin === true) {
    return children;
  } else if (!pending && (!isAuthenticated || (isAuthenticated && !isAdmin))) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }
};

export default ProtectedRoute;
