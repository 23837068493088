import { useParams } from "react-router-dom";
import AddTrendBox from "../components/AddTrendBox";
import RadarForAddAndUpdate from "../components/RadarForAddAndUpdate";



const AddTrend = () => {
    const { id,isAdd } = useParams();
    return (
        <div className="flex flex-col items-center w-screen h-screen">
            <div className="flex lg:max-w-[1350px] w-full h-full">
                <AddTrendBox isAdd={isAdd==="true"} id={id} />
                <RadarForAddAndUpdate isAdd={isAdd==="true"} id={id} />
            </div>
        </div>
    );

}
export default AddTrend;