const Description = ({firstParagraph,secondParagraph,thirdParagraph}) => {
    return <div className="flex flex-col flex-grow pl-[25px] ">
        <h2 className="text-primary text-md font-bold md:pt-8">
           {firstParagraph}
        </h2>
        <h2 className="text-primary text-md md:pt-4">
           {secondParagraph}
        </h2>
        <h2 className="text-primary text-md md:pt-4">
           {thirdParagraph}
        </h2>
    </div>
}

export default Description;