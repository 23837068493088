import ExpandedList from './ExpandedList';
import real_estate_trend_radar_text from '../images/real_estate_trend_radar_text.png';
import aldar_logo from '../images/aldar_logo.png'

const Filter = () => {
    return (
        <div className="lg:flex hidden w-[500px] flex-col overflow-auto h-screen items-start justify-start p-10 bg-[#D9D9D9]">
           <div className="flex flex-col">
           <img src={aldar_logo} alt={"aldar logo"} className='w-16 bg-primary' />
           <img src={real_estate_trend_radar_text} alt="real_estate" className='w-60 pt-10' />
            <h1 className='flex text-3xl font-bold pt-12'>
                Trends Filter
            </h1>
            <h1 className='flex text-sm font-normal py-3 select-none text-start'>
                Use the toggles below to filter visible trends.
            </h1>
            <ExpandedList expandedFilter={false}/>
           </div>
        </div>
    );
}
export default Filter;