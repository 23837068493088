import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';

import history from './history';
import { baseURL } from './constants';
import useTrendsStore from './state/trend.state';
import useAuthStore from './state/auth.state';
import TrendDescription from './pages/TrendDescription';
import Home from './pages/Home';
import AddTrend from './pages/AddTrend';
import ControlTrends from './pages/ControlTrends';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const { setTrends } = useTrendsStore();
  const { setAccessToken } = useAuthStore();
  const { instance, accounts, inProgress } = useMsal();

  const request = {
    scopes: [process.env.REACT_APP_TRENDS_API_SCOPE],
  };

  useEffect(() => {
    const fetchTrends = (accessToken) => {
      const aldarApi = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      aldarApi
        .get('/api/trends/get_all')
        .then((response) => {
          let fetchedTrends = response.data;
          fetchedTrends = fetchedTrends.map((t) => {
            t['isSelectedForFilter'] = false;
            return t;
          });
          setTrends(fetchedTrends);
        })
        .catch((error) => {
          console.log('Error in fetching trends: ', error);
        });
    };

    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          ...request,
          account: accounts[0],
        })
        .then((res) => {
          const accessToken = res.accessToken;
          setAccessToken(accessToken);
          fetchTrends(accessToken);
        })
        .catch((error) => {
          console.log('Error in acquiring token silently:', error);
          instance
            .acquireTokenRedirect(request)
            .then((res) => {
              const accessToken = res.accessToken;
              setAccessToken(accessToken);
              fetchTrends(accessToken);
            })
            .catch((err) => console.log('Error in acquiring token by redirect:', err));
        });
    }
  }, [inProgress]);

  return (
    <Router history={history}>
      <div className="flex flex-col w-screen h-screen items-center">
        <div className="flex flex-grow w-full h-full overflow-auto">
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route
              exact
              path="/control_trends"
              element={
                <ProtectedRoute>
                  <ControlTrends />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/add_trend/:isAdd"
              element={
                <ProtectedRoute>
                  <AddTrend />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/update_trend/:isAdd/:id/:title"
              element={
                <ProtectedRoute>
                  <AddTrend />
                </ProtectedRoute>
              }
            />
            <Route exact path="/trend_description/:id/:title" element={<TrendDescription />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
