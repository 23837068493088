import blochchain from '../images/blockchain.png'

const ImageAndTitle = ({ title,image }) => {

    return <div className="flex relative w-full">
           {
                               image&&
                               <img src={image} className='w-full' alt="trend_image" />
                               }
                               {!image&&
                                 <img src={blochchain} className='w-full' alt="trend_image" />
                               }
            <h1 className="text-2lg text-white bg-primary px-5 lg:text-5xl text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-fit"> {title}</h1>
    </div>
}

export default ImageAndTitle;