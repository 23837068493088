import sectors from '../images/sector.png'

const Section = ({ imageSource, title, text }) => {
    return <div className="flex flex-col items-center justify-start max-w-[300px]">
        <div className="flex flex-row items-center justify-start">
            <img src={imageSource} className='w-10 h-8' alt="" />
            <h1 className='text-center md:text-xl text-lg font-bold pl-2'>
                {title}
            </h1>
        </div>
        <h1 className='text-center text-md pt-[20px]'>
            {text}
        </h1>
    </div>
}

export default Section;