import { useCollapse } from 'react-collapsed'
import ExpandedList from './ExpandedList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const ExpandedFilter = () => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    return (
        <div className="flex flex-col items-start justify-between absolute lg:hidden top-[20px] left-[20px]">
            <KeyboardArrowDownIcon {...getToggleProps()} />
            <section className='flex w-[300px]' {...getCollapseProps()}>
                <div className="flex pt-[10px] bg-[#2C2A29]">
                    <ExpandedList expandedFilter={true}/>
                </div>
            </section>
        </div>
    );
}
export default ExpandedFilter;