import challenge from '../images/challenge.png'
import outlook_img from '../images/outlook_img.png'
import radar_back from '../images/radar_back.svg'
import { useNavigate } from "react-router-dom";

const TrendAnalysis = ({ risksList, outlook }) => {
    const navigate = useNavigate();

    return <div className="flex flex-col bg-secondary p-16 relative">
        <div className="flex items-center justify-center">
            <h1 className="text-3xl text-dark_blue font-bold pb-12">
                Trend Analysis
            </h1>
        </div>

        <div className="flex flex-row">
            <img className='w-14 h-10 pr-4' src={challenge} alt="" />
            <div className="flex flex-col pb-4">
                <h1 className="text-xl font-bold text-primary">
                    Risks and Challenge
                </h1>
                {
                    risksList.map((risksItem) => {
                        return <h2 className="text-md text-primary ">
                            {risksItem}
                        </h2>
                    })
                }
            </div>
        </div>
        <div className="flex flex-row">
            <img className='w-16 h-8 pr-4' src={outlook_img} alt="" />
            <div className="flex flex-col pb-4">
                <h1 className="text-xl font-bold text-primary">
                    Outlook
                </h1>
                {
                    outlook.map((outlookItem) => {
                        return <h2 className="text-md text-primary ">
                            {outlookItem}
                        </h2>
                    })
                }
            </div>
        </div>
        <div className="flex flex-row  absolute bottom-4 right-8">
            <img src={radar_back} className='w-8 h-8' alt="back" />
            <button className='pl-2' onClick={() => navigate(-1)}>
                <h1 className='underline'>
                    Back to Radar
                </h1>
            </button>
        </div>

    </div>
}

export default TrendAnalysis;