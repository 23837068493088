import { Switch } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCollapse } from 'react-collapsed';

const ExpandedItem = ({ expandedFilter, title, items }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div>
      <div
        className="flex w-[300px] justify-between hover:cursor-pointer hover:text-red-500 py-5 px-5 lg:px-0"
        {...getToggleProps()}
      >
        <h1 className={`text-md font-bold select-none ${expandedFilter ? `text-white` : `text-black`}`}>{title}</h1>
        {!isExpanded && <KeyboardArrowDownIcon />}
        {isExpanded && <KeyboardArrowUpIcon />}
      </div>
      <section className="w-[300px]" {...getCollapseProps()}>
        {items.map((item, index, s) => {
          return (
            <div key={index} className="flex justify-between items-center px-5 lg:px-0">
              <h1 className={`select-none text-sm ${expandedFilter ? `text-white` : `text-black`}`} onClick={() => {}}>
                {item.title}
              </h1>
              <Switch
                onChange={(event, checked) => {
                  item.onClick(checked);
                }}
              />
            </div>
          );
        })}
      </section>
    </div>
  );
};
export default ExpandedItem;
