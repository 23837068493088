import { create } from 'zustand'

const usePositionStore = create((set) => ({
    x: 0,
    y:0,
    setX: (x) =>
    set((state) => {
      return {
        ...state,
        x: x
      };
    }),
    setY: (y) =>
    set((state) => {
      return {
        ...state,
        y: y
      };
    }),

  }))

  export default usePositionStore