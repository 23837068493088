import { IconButton } from '@mui/material';
import blockchain from '../images/blockchain.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import useTrendsStore from '../state/trend.state';
import useAuthStore from '../state/auth.state';
import { useState } from 'react';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { Button } from '@mui/material';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {Link} from 'react-router-dom';
import { baseURL } from '../constants';


const ControlTrends = () => {
    const { trends, setTrends } = useTrendsStore();
    const { accessToken } = useAuthStore();
    const [openDialog, handleDisplay] = useState(false);
    const [selectedTrendId, setSelectedTrendId] = useState(false);

    const aldarApi = axios.create({
      baseURL: baseURL,
      timeout: 5000,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const handleClose = () => {
        handleDisplay(false);
    };

    const openDialogBox = (id) => {
        setSelectedTrendId(id)
        handleDisplay(true);
    };

 
    return (
        <div className="flex flex-col items-center w-full h-full">
            <ul className="flex flex-col lg:max-w-[1350px] w-full h-full overflow-auto">
                {
                    trends.map((trend, index) => {
                        return <li key={index} className="flex w-full p-7 mb-1 justify-between items-center border-b-2 stroke-1">
                            <div className="flex items-center ">
                               {
                               trend.imageURL&&
                               <img src={trend.imageURL} className='w-24 h-24' alt="trend_image" />
                               }
                               {!trend.imageURL&&
                                 <img src={blockchain} className='w-24 h-24' alt="trend_image" />
                               }
                                <h1 className="font-bold text-lg pl-5">
                                    {trend.title}
                                </h1>
                            </div>
                            <div className="flex space-x-4 h-12">
                                <Link to={{
                                    pathname: `/update_trend/false/${trend.id}/${trend.title}`
                                }}>
                                    <IconButton size='medium'>
                                        <EditIcon />
                                    </IconButton>
                                </Link >
                                <IconButton size='medium' onClick={async () => {
                                    openDialogBox(trend.id)
                                }}>
                                    <DeleteForeverIcon />
                                </IconButton>

                            </div>
                        </li>
                    })
                }
            </ul>
            <Dialog onClose={handleClose} open={openDialog}>
                <div className="flex flex-col items-start">

                    <div className="flex flex-col p-4 items-center">
                        <h2 className="text-lg">
                            Are you sure you want to delete this trend?
                        </h2>

                        <div className="flex flex-row gap-2">
                            <Button onClick={async () => {
                                try {

                                    var response = (await aldarApi.delete(`/api/trends/delete?id=${selectedTrendId}`));
                                    if (response.status === 200) {
                                        const trend = trends.filter((t) => { return t.id === selectedTrendId })[0]
                                        const indexOfDeletedItem = trends.indexOf(trend);
                                        trends.splice(indexOfDeletedItem, 1);
                                        setTrends(trends)
                                        handleClose()
                                        console.log(indexOfDeletedItem)
                                    } else {
                                        console.log('something went wrong');
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            }} variant='contained' style={{
                                backgroundColor: "#000000",
                                margin: "18px 0px",
                            }}>
                                Delete
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClose()
                                }}
                                variant='contained' style={{
                                    backgroundColor: "#000000",
                                    margin: "18px 0px",
                                }}>
                                Cancel
                            </Button>
                        </div>

                    </div>
                </div>

            </Dialog>
          <div className="flex justify-end absolute bottom-0 w-full px-40 py-4">
          <Link to={{
                pathname: `/add_trend/true`

            }}>
                <Fab aria-label="add" style={{
                    backgroundColor: "#000000",
                    color: "#ffffff"
                }}>
                    <AddIcon />
                </Fab>
            </Link>
          </div>
        </div>
    )
}

export default ControlTrends;