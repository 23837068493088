import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import usePositionStore from '../state/position.state';
import useTrendsStore from '../state/trend.state';
import useAuthStore from '../state/auth.state';
import axios from 'axios';
import { baseURL } from '../constants';
import ReactStars from 'react-rating-stars-component';

const AddTrendBox = ({ isAdd, id }) => {
  const { x, y } = usePositionStore();
  const { trends, setTrends } = useTrendsStore();
  const { accessToken } = useAuthStore();
  const [image, setImage] = useState();
  const trend = trends.filter((t) => {
    return t.id === parseInt(id);
  })[0];

  const aldarApi = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  useEffect(() => {
    if (trend && !isAdd) {
      formik.setValues({
        title: trend.title ?? '',
        firstParagraph: trend.firstParagraph ?? '',
        secondParagraph: trend.secondParagraph ?? '',
        thirdParagraph: trend.thirdParagraph ?? '',
        favorite: trend.isFavorite ? 1 : 2 ?? '',
        shortDescription: trend.shortDescription ?? '',
        risksAndChallenge: trend.risksAndChallenge ?? [],
        outlook: trend.outlook ?? '',
        relevanceToTheFutureOfRealEstate: trend.relevanceToTheFutureOfRealEstate ?? [
          {
            title: '',
            description: '',
            sourceLink: '',
            imageURL: '',
            readinessImageUrl: '',
            useCaseImageUrl: '',
            strategicFit: 0,
            painPointRelevance: 0,
            scalability: 0,
            teckMaturity: 0,
            simplicity: 0,
            innoKnowHowAndAvailability: 0,
          },
        ],
        value: trend.value ?? '',
        impact: trend.impact ?? '',
        timeHorizon: trend.timeHorizon ?? '',
        complexity: trend.complexity ?? '',
        trend: trend.isSocial ? 2 : 1 ?? '',
        focusAreas: trend.focusAreas ?? [],
        trendClusters: trend.trendClusters ?? [],
        sectors: trend.sectors ?? [],
      });
    }
  }, [trend]);

  const formik = useFormik({
    initialValues: {
      title: '',
      firstParagraph: '',
      secondParagraph: '',
      thirdParagraph: '',
      shortDescription: '',
      favorite: '',
      risksAndChallenge: [''],
      outlook: '',
      relevanceToTheFutureOfRealEstate: [
        {
          title: '',
          description: '',
          sourceLink: '',
          imageUrl: '',
          strategicFit: '0',
          painPointRelevance: '0',
          scalability: '0',
          teckMaturity: '0',
          simplicity: '0',
          innoKnowHowAndAvailability: '0',
        },
      ],
      value: '',
      impact: '',
      timeHorizon: '',
      complexity: '',
      trend: '',
      focusAreas: [],
      trendClusters: [],
      sectors: [],
    },
    validate: (values) => {
      const errors = {};

      if (!values.title) {
        errors.title = 'Field cannot be empty.';
      }
      if (!values.firstParagraph) {
        errors.description = 'Field cannot be empty.';
      }
      if (!values.secondParagraph) {
        errors.description = 'Field cannot be empty.';
      }
      if (!values.thirdParagraph) {
        errors.description = 'Field cannot be empty.';
      }
      if (!values.shortDescription) {
        errors.shortDescription = 'Field cannot be empty.';
      }
      if (!values.value) {
        errors.value = 'Please select a value.';
      }

      if (!values.impact) {
        errors.impact = 'Please select an impact.';
      }

      if (!values.timeHorizon) {
        errors.timeHorizon = 'Please select a time horizon.';
      }

      if (!values.complexity) {
        errors.complexity = 'Please select a complexity level.';
      }
      if (values.risksAndChallenge.some((risk) => !risk)) {
        errors.risksAndChallenge = 'Field cannot be empty.';
      }
      if (!values.outlook) {
        errors.outlook = 'Field cannot be empty.';
      }
      if (values.relevanceToTheFutureOfRealEstate.some((item) => !item.title || !item.description)) {
        errors.relevanceToTheFutureOfRealEstate = 'Field cannot be empty.';
      }
      if (!values.trend) {
        errors.trend = 'Please select a trend.';
      }
      if (!values.favorite) {
        errors.trend = 'Please select a favorite type.';
      }

      if (values.focusAreas.length === 0) {
        errors.focusAreas = 'Please select at least one focus area.';
      }

      if (values.trendClusters.length === 0) {
        errors.trendClusters = 'Please select at least one trend cluster.';
      }
      if (values.sectors.length === 0) {
        errors.sectors = 'Please select at least one sector.';
      }

      return errors;
    },
    onSubmit: async (values) => {
      formik.setTouched({
        title: true,
        firstParagraph: true,
        secondParagraph: true,
        thirdParagraph: true,
        shortDescription: true,
      });

      if (Object.keys(formik.errors).length === 0) {
        const trend = values;
        trend['x'] = parseFloat(x);
        trend['y'] = parseFloat(y);
        let complexity = trend.complexity;
        trend.complexity = parseFloat(complexity);
        let impact = trend.impact;
        trend.impact = parseFloat(impact);
        let timeHorizon = trend.timeHorizon;
        trend.timeHorizon = parseFloat(timeHorizon);
        let value = trend.value;
        trend.value = parseFloat(value);
        trend.focusAreas = trend.focusAreas.map((focarea) => {
          return parseInt(focarea);
        });
        trend.trendClusters = trend.trendClusters.map((cluster) => {
          return parseInt(cluster);
        });
        trend.sectors = trend.sectors.map((sector) => {
          return parseInt(sector);
        });
        trend.trendClusters = trend.trendClusters.filter(function (value) {
          return !Number.isNaN(value);
        });
        trend.focusAreas = trend.focusAreas.filter(function (value) {
          return !Number.isNaN(value);
        });
        trend.sectors = trend.sectors.filter(function (value) {
          return !Number.isNaN(value);
        });
        trend.isSocial = trend.trend === '2';
        trend.isFavorite = trend.favorite === '1';
        if (image) {
          trend.imageURL = image;
        }

        console.log(values);
        if (isAdd) {
          try {
            const response = await aldarApi.post('/api/trends/create', trend);
            if (response.status === 201) {
              trend['id'] = parseInt(response.data.data.id);
              console.log(response);
              trends.push(trend);
              setTrends(trends);
            } else {
              console.log('something went wrong');
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            trend['id'] = parseInt(id);
            const response = await aldarApi.put('/api/trends/update', trend);
            if (response.status === 200) {
              console.log(response);
              const indexOfUpdatedItem = trends.indexOf(
                trends.filter((t) => {
                  return t.id === parseInt(id);
                })[0]
              );
              console.log(trend);
              trends[indexOfUpdatedItem] = trend;
              setTrends(trends);
            } else {
              console.log('something went wrong');
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
  });

  // Handlers for dynamic field actions
  const addRiskChallenge = () => {
    const newRisks = [...formik.values.risksAndChallenge, ''];
    formik.setFieldValue('risksAndChallenge', newRisks);
  };

  const deleteRiskChallenge = (index) => {
    const newRisks = [...formik.values.risksAndChallenge];
    newRisks.splice(index, 1);
    formik.setFieldValue('risksAndChallenge', newRisks);
  };

  const addItemRelevance = () => {
    const newItems = [...formik.values.relevanceToTheFutureOfRealEstate, { title: '', description: '' }];
    formik.setFieldValue('relevanceToTheFutureOfRealEstate', newItems);
  };

  const deleteItemRelevance = (index) => {
    const newItems = [...formik.values.relevanceToTheFutureOfRealEstate];
    newItems.splice(index, 1);
    formik.setFieldValue('relevanceToTheFutureOfRealEstate', newItems);
  };

  const handleFileChangeForTrend = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      console.log(files[0]);
      const formData = new FormData();
      formData.append('file', files[0]);
      aldarApi
        .post('/api/files/upload', formData)
        .then((res) => {
          if (res.status === 201) {
            console.log(res);
            setImage(res.data.image_url);
          } else {
            console.log('something went wrong');
          }
        })
        .catch((e) => {
          console.log('Error in uploading files: ', e);
          event.target.value = null;
        });
    }
  };

  const handleFileChangeForUseCase = (event, key) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      console.log(files[0]);
      const formData = new FormData();
      formData.append('file', files[0]);
      aldarApi
        .post('/api/files/upload', formData)
        .then((res) => {
          if (res.status === 201) {
            formik.setFieldValue(key, res.data.image_url);
          } else {
            console.log('something went wrong');
            event.target.value = null;
          }
        })
        .catch((e) => {
          console.log('Error in uploading files: ', e);
          event.target.value = null;
        });
    }
  };
  const handleFileChangeForRelevance = (event, index) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      console.log(files[0]);
      const formData = new FormData();
      formData.append('file', files[0]);
      aldarApi.post('/api/files/upload', formData).then((res) => {
        if (res.status === 201) {
          formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].imageUrl`, res.data.image_url);
        } else {
          console.log('something went wrong');
        }
      });
    }
  };

  // Define the checkbox values and labels for each section
  const focusAreasOptions = [
    'Efficiency & Synergy',
    'Customer Experience',
    'Employee Welfare & Future of Work',
    'Agility & Resilience',
    'ESG & Sustainability',
    'Digital/Cyber/Data Security',
  ];

  const trendClustersOptions = [
    'Hyper Connectivity',
    'AI & Big Data',
    'Cloud & Computing',
    'Immersive Reality',
    'Web3.0 & Blockchain',
    'Physical & Digital Security & Privacy',
    'IoT & Next-Gen Industrialization',
    'Social Trends',
  ];

  const sectorsOptions = [
    'Property Development & Sales',
    'Aldar Projects',
    'Investment Properties',
    'Hospitality & Leisure',
    'Education',
    'Aldar Estates',
  ];
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col h-full overflow-auto max-w-[430px]">
        <div className="w-full max-w-[350px] ml-10 pr-2 mr-3 mt-10 space-y-3">
          <div>
            <label>banner image</label>
            <input type="file" accept="image/*" onChange={handleFileChangeForTrend} />
          </div>
          <div>
            <label>readiness image</label>
            <input
              name={`readinessImageUrl`}
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChangeForUseCase(e, 'readinessImageUrl');
              }}
            />
          </div>
          <div>
            <label>evaluation image</label>
            <input
              name={`useCaseImageUrl`}
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileChangeForUseCase(e, 'useCaseImageUrl');
              }}
            />
          </div>
          <input
            name="title"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.title}
            placeholder="Title"
            className="w-full px-3 py-2 border rounded-md"
          />
          {formik.errors.title && formik.touched.title ? (
            <div className="text-red-500">{formik.errors.title}</div>
          ) : null}
          <input
            name="firstParagraph"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstParagraph}
            placeholder="First Paragraph"
            className="w-full px-3 py-2 border rounded-md"
          />
          {formik.errors.firstParagraph && formik.touched.firstParagraph ? (
            <div className="text-red-500">{formik.errors.firstParagraph}</div>
          ) : null}
          <input
            name="secondParagraph"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.secondParagraph}
            placeholder="Second Paragraph"
            className="w-full px-3 py-2 border rounded-md"
          />
          {formik.errors.secondParagraph && formik.touched.secondParagraph ? (
            <div className="text-red-500">{formik.errors.secondParagraph}</div>
          ) : null}
          <input
            name="thirdParagraph"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.thirdParagraph}
            placeholder="Third Paragraph"
            className="w-full px-3 py-2 border rounded-md"
          />
          {formik.errors.thirdParagraph && formik.touched.thirdParagraph ? (
            <div className="text-red-500">{formik.errors.thirdParagraph}</div>
          ) : null}

          <input
            name="shortDescription"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.shortDescription}
            placeholder="Short Description"
            className="w-full px-3 py-2 border rounded-md"
          />
          {formik.errors.shortDescription && formik.touched.shortDescription ? (
            <div className="text-red-500">{formik.errors.shortDescription}</div>
          ) : null}
        </div>
        <div className="w-full max-w-md ml-10 mx-3 mt-10 space-y-3">
          <fieldset className="space-y-2">
            <legend className="font-bold">Value</legend>
            {(isAdd || formik.values.value) &&
              [1, 2, 3].map((value) => (
                <label key={value} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="value"
                    value={value}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.value === value : null}
                  />
                  <span>{value}</span>
                </label>
              ))}
            {formik.errors.value && formik.touched.value ? (
              <div className="text-red-500">{formik.errors.value}</div>
            ) : null}
          </fieldset>
          <fieldset className="space-y-2">
            <legend className="font-bold">Impact</legend>
            {(isAdd || formik.values.impact) &&
              [1, 2, 3, 4, 5].map((value, index) => (
                <label key={value} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="impact"
                    value={value}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.impact === value : null}
                  />
                  <span>{value}</span>
                </label>
              ))}
            {formik.errors.impact && formik.touched.impact ? (
              <div className="text-red-500">{formik.errors.impact}</div>
            ) : null}
          </fieldset>
          <fieldset className="space-y-2">
            <legend className="font-bold">Time Horizon</legend>
            {(isAdd || formik.values.timeHorizon) &&
              [1, 2, 3, 4, 5].map((value, index) => (
                <label key={value} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="timeHorizon"
                    value={value}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.timeHorizon === value : null}
                  />
                  <span>{value}</span>
                </label>
              ))}
            {formik.errors.timeHorizon && formik.touched.timeHorizon ? (
              <div className="text-red-500">{formik.errors.timeHorizon}</div>
            ) : null}
          </fieldset>
          <fieldset className="space-y-2">
            <legend className="font-bold">Complexity</legend>
            {(isAdd || formik.values.complexity) &&
              [1, 2, 3, 4, 5].map((value, index) => (
                <label key={value} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="complexity"
                    value={value}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.complexity === value : null}
                  />
                  <span>{value}</span>
                </label>
              ))}
            {formik.errors.complexity && formik.touched.complexity ? (
              <div className="text-red-500">{formik.errors.complexity}</div>
            ) : null}
          </fieldset>
          <fieldset className="flex space-x-4">
            <legend className="font-bold mr-4">Favorite</legend>
            {(isAdd || formik.values.favorite) &&
              ['Yes', 'No'].map((favorite, index) => (
                <label key={favorite} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="favorite"
                    value={index + 1}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.favorite === index + 1 : null}
                  />
                  <span>{favorite}</span>
                </label>
              ))}
          </fieldset>
          <fieldset className="flex space-x-4">
            <legend className="font-bold mr-4">Trend</legend>
            {(isAdd || formik.values.trend) &&
              ['Technology Trend', 'Social and Business Trend'].map((trend, index) => (
                <label key={trend} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="trend"
                    value={index + 1}
                    onChange={formik.handleChange}
                    defaultChecked={!isAdd ? formik.values.trend === index + 1 : null}
                  />
                  <span>{trend}</span>
                </label>
              ))}
          </fieldset>
          {formik.errors.trend && <div className="text-red-500">{formik.errors.trend}</div>}
          <fieldset className="space-y-2">
            <legend className="font-bold">Focus Areas</legend>
            {(isAdd || formik.values.focusAreas.length !== 0) &&
              focusAreasOptions.map((label, index) => (
                <label key={`focusAreas-[${index}]`} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={`focusAreas[${index}]`}
                    value={index + 1}
                    onChange={() => {
                      if (formik.values.focusAreas.includes(index + 1)) {
                        let indexof = formik.values.focusAreas.indexOf(index + 1);
                        formik.values.focusAreas.splice(indexof, 1);
                      } else {
                        formik.values.focusAreas.push(index + 1);
                      }
                      formik.values.focusAreas = formik.values.focusAreas.filter(
                        (item, index) => formik.values.focusAreas.indexOf(item) === index
                      );
                    }}
                    defaultChecked={!isAdd ? formik.values.focusAreas.includes(index + 1) : null}
                  />
                  <span>{label}</span>
                </label>
              ))}
            {formik.errors.focusAreas && <div className="text-red-500">{formik.errors.focusAreas}</div>}
          </fieldset>
          <fieldset className="space-y-2">
            <legend className="font-bold">Trend Clusters</legend>
            {(isAdd || formik.values.trendClusters.length !== 0) &&
              trendClustersOptions.map((label, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={`trendClusters[${index}]`}
                    value={index + 1}
                    onChange={() => {
                      if (formik.values.trendClusters.includes(index + 1)) {
                        let indexof = formik.values.trendClusters.indexOf(index + 1);
                        formik.values.trendClusters.splice(indexof, 1);
                      } else {
                        formik.values.trendClusters.push(index + 1);
                      }
                      formik.values.trendClusters = formik.values.trendClusters.filter(
                        (item, index) => formik.values.trendClusters.indexOf(item) === index
                      );
                    }}
                    defaultChecked={!isAdd ? formik.values.trendClusters.includes(index + 1) : null}
                  />
                  <span>{label}</span>
                </label>
              ))}
          </fieldset>
          {formik.errors.trendClusters && <div className="text-red-500">{formik.errors.trendClusters}</div>}
          <fieldset className="space-y-2">
            <legend className="font-bold">Sectors</legend>
            {(isAdd || formik.values.sectors.length !== 0) &&
              sectorsOptions.map((label, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="sectors"
                    value={index + 1}
                    onChange={() => {
                      if (formik.values.sectors.includes(index + 1)) {
                        let indexof = formik.values.sectors.indexOf(index + 1);
                        formik.values.sectors.splice(indexof, 1);
                      } else {
                        formik.values.sectors.push(index + 1);
                      }
                      formik.values.sectors = formik.values.sectors.filter(
                        (item, index) => formik.values.sectors.indexOf(item) === index
                      );
                    }}
                    defaultChecked={!isAdd ? formik.values.sectors.includes(index + 1) : null}
                  />
                  <span>{label}</span>
                </label>
              ))}
          </fieldset>
          {formik.errors.sectors && <div className="text-red-500">{formik.errors.sectors}</div>}
        </div>
        <div className="w-full max-w-[390px] ml-10 mx-3 mt-10 space-y-3">
          <label className="block text-md font-bold mb-2">Risks And Challenge</label>
          {formik.values.risksAndChallenge.map((risk, index) => (
            <div key={`risk-${index}`}>
              <div className="flex items-start">
                <div className="flex-grow">
                  <input
                    name={`risksAndChallenge[${index}]`}
                    type="text"
                    onChange={formik.handleChange}
                    value={risk}
                    placeholder="text"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                  {formik.errors.risksAndChallenge && (
                    <div className="text-red-500">{formik.errors.risksAndChallenge}</div>
                  )}
                </div>
                <div className="ml-4">
                  <button type="button" onClick={() => deleteRiskChallenge(index)}>
                    <DeleteIcon />
                    {/* <img src={Delete} alt="delete icon" width={20} /> */}
                  </button>
                </div>
              </div>
            </div>
          ))}

          {
            <div className="flex justify-center">
              <button
                type="button"
                onClick={addRiskChallenge}
                className="px-5 py-2 text-white bg-black rounded-md hover:bg-gray-700"
              >
                Add
              </button>
            </div>
          }
        </div>
        <div className="w-full max-w-[390px] ml-10 mx-3 mt-10 space-y-3">
          <label className="block text-md font-bold mb-2">Outlook</label>
          <div className="flex-grow">
            <input
              name="outlook"
              type="text"
              onChange={formik.handleChange}
              placeholder="text"
              value={formik.values.outlook}
              className="w-full px-3 py-2 border rounded-md"
            />
            {formik.errors.outlook && <div className="text-red-500 mt-1">{formik.errors.outlook}</div>}
          </div>
        </div>
        <div className="w-full max-w-[390px] ml-10 mx-3 mt-10 space-y-3">
          <label className="block text-md font-bold mb-2">Relevance to The Future of Real Estate</label>
          {formik.values.relevanceToTheFutureOfRealEstate.map((item, index) => (
            <div key={`relevance-${index + 1}`} className="mb-4">
              <div className="flex flex-col">
                <label>image</label>
                <input
                  name={`relevanceToTheFutureOfRealEstate[${index}].imageUrl`}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleFileChangeForRelevance(e, index);
                  }}
                />

                <div className="flex items-start justify-between space-x-4">
                  <div className="flex-grow">
                    <input
                      name={`relevanceToTheFutureOfRealEstate[${index}].title`}
                      type="text"
                      onChange={formik.handleChange}
                      value={item.title}
                      placeholder="title"
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  </div>

                  <div className="flex-grow">
                    <input
                      name={`relevanceToTheFutureOfRealEstate[${index}].description`}
                      type="text"
                      onChange={formik.handleChange}
                      value={item.description}
                      placeholder="description"
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  </div>
                </div>
                <div className="flex-grow pt-2">
                  <input
                    name={`relevanceToTheFutureOfRealEstate[${index}].sourceLink`}
                    type="text"
                    onChange={formik.handleChange}
                    value={item.sourceLink}
                    placeholder="source link"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
                <div className="flex flex-col w-80">
                  <div className="flex flex-row items-center justify-between gap-24 pb-2">
                    <h5 className="text-xs font-bold">Strategic fit</h5>
                    <div className="flex flex-row items-end">
                      {/* <Rating
                                                SVGstyle={
                                                    { 'display': 'inline' }
                                                }
                                                iconsCount={3}
                                                name={`relevanceToTheFutureOfRealEstate[${index}].strategicFit`}
                                                onClick={(strategicFitValue) => {
                                                    console.log(strategicFitValue)
                                                    formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].strategicFit`, `${strategicFitValue}`)
                                                }}

                                                initialValue={item.strategicFit}
                                                allowFraction={false}
                                                allowHover={false}
                                                size={15}
                                            /> */}
                      {item != null && item.strategicFit != null ? (
                        <ReactStars
                          count={3}
                          name={`relevanceToTheFutureOfRealEstate[${index}].strategicFit`}
                          onChange={(strategicFit) => {
                            console.log(item.strategicFit);
                            formik.setFieldValue(
                              `relevanceToTheFutureOfRealEstate[${index}].strategicFit`,
                              `${strategicFit}`
                            );
                          }}
                          value={item.strategicFit}
                          size={18}
                          activeColor="#ffd700"
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between pb-2">
                    <h5 className="text-xs font-bold">Pain Point Relevance</h5>
                    {/* <Rating
                                            initialValue={item.painPointRelevance}
                                            name={`relevanceToTheFutureOfRealEstate[${index}].painPointRelevance`}
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            onClick={(painPointRelevance) => {
                                                formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].painPointRelevance`, `${painPointRelevance}`)
                                            }}
                                            allowFraction={false}
                                            allowHover={false}
                                            size={15}
                                        /> */}
                    <ReactStars
                      count={3}
                      name={`relevanceToTheFutureOfRealEstate[${index}].painPointRelevance`}
                      onChange={(painPointRelevance) => {
                        console.log(item.strategicFit);
                        formik.setFieldValue(
                          `relevanceToTheFutureOfRealEstate[${index}].painPointRelevance`,
                          `${painPointRelevance}`
                        );
                      }}
                      value={item.painPointRelevance}
                      size={18}
                      activeColor="#ffd700"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between  pb-2">
                    <h5 className="text-xs font-bold">Scalability</h5>
                    {/* <Rating
                                            initialValue={item.scalability}
                                            name={`relevanceToTheFutureOfRealEstate[${index}].scalability`}
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            onClick={(scalability) => {
                                                formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].scalability`, `${scalability}`)
                                            }}
                                            allowFraction={false}
                                            allowHover={false}
                                            size={15}
                                        /> */}
                    <ReactStars
                      count={3}
                      name={`relevanceToTheFutureOfRealEstate[${index}].scalability`}
                      onChange={(scalability) => {
                        console.log(item.scalability);
                        formik.setFieldValue(
                          `relevanceToTheFutureOfRealEstate[${index}].scalability`,
                          `${scalability}`
                        );
                      }}
                      value={item.scalability}
                      size={18}
                      activeColor="#ffd700"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between  pb-2">
                    <h5 className="text-xs font-bold">Teck Maturity</h5>
                    {/* <Rating
                                            initialValue={item.teckMaturity}
                                            name={`relevanceToTheFutureOfRealEstate[${index}].teckMaturity`}
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            onClick={(teckMaturity) => {
                                                formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].teckMaturity`, `${teckMaturity}`)
                                            }}
                                            allowFraction={false}
                                            allowHover={false}
                                            size={15}
                                        /> */}
                    <ReactStars
                      count={3}
                      name={`relevanceToTheFutureOfRealEstate[${index}].teckMaturity`}
                      onChange={(teckMaturity) => {
                        console.log(item.teckMaturity);
                        formik.setFieldValue(
                          `relevanceToTheFutureOfRealEstate[${index}].teckMaturity`,
                          `${teckMaturity}`
                        );
                      }}
                      value={item.teckMaturity}
                      size={18}
                      activeColor="#ffd700"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between  pb-2">
                    <h5 className="text-xs font-bold">Simplicity</h5>
                    {/* <Rating
                                            initialValue={item.simplicity}
                                            SVGstyle={
                                                { 'display': 'inline' }
                                            }
                                            iconsCount={3}
                                            name={`relevanceToTheFutureOfRealEstate[${index}].simplicity`}
                                            onClick={(simplicity) => {
                                                formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].simplicity`, `${simplicity}`)
                                            }}
                                            allowFraction={false}
                                            allowHover={false}
                                            size={15}
                                        /> */}
                    <ReactStars
                      count={3}
                      name={`relevanceToTheFutureOfRealEstate[${index}].simplicity`}
                      onChange={(simplicity) => {
                        console.log(item.simplicity);
                        formik.setFieldValue(`relevanceToTheFutureOfRealEstate[${index}].simplicity`, `${simplicity}`);
                      }}
                      value={item.simplicity}
                      size={18}
                      activeColor="#ffd700"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between  pb-2">
                    <div className="flex w-56">
                      <h5 className="text-xs font-bold">Inno Know-How & Availability</h5>
                    </div>
                    <ReactStars
                      count={3}
                      name={`relevanceToTheFutureOfRealEstate[${index}].innoKnowHowAndAvailability`}
                      onChange={(innoKnowHowAndAvailability) => {
                        console.log(item.innoKnowHowAndAvailability);
                        formik.setFieldValue(
                          `relevanceToTheFutureOfRealEstate[${index}].innoKnowHowAndAvailability`,
                          `${innoKnowHowAndAvailability}`
                        );
                      }}
                      value={item.innoKnowHowAndAvailability}
                      size={18}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
              <button type="button" onClick={() => deleteItemRelevance(index)}>
                {/* <img src={Delete} alt="delete icon" width={20} /> */}
                <DeleteIcon />
              </button>
            </div>
          ))}

          {formik.values.relevanceToTheFutureOfRealEstate &&
          formik.values.relevanceToTheFutureOfRealEstate.length < 12 ? (
            <div className="flex justify-center">
              <button
                type="button"
                onClick={addItemRelevance}
                className="px-5 py-2 text-white bg-black rounded-md hover:bg-gray-700"
              >
                Add
              </button>
            </div>
          ) : null}
        </div>
        <div className="flex justify-center pt-16 pb-8">
          <button type="submit" className=" bg-black text-white font-bold py-2 px-10 rounded">
            Ok
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddTrendBox;
