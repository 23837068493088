import Description from '../components/Description';
import ImageAndTitle from '../components/ImageAndTitle';
import Section from '../components/Section';
import TrendOverview from '../components/TrendOverview';
import sectors_image from '../images/sector.png'
import focus_area from '../images/focus_area.png'
import trend_clusters from '../images/trend_clusters.png'
import RelevanceToTheFuture from '../components/RelevanceToTheFuture';
import TrendAnalysis from '../components/TrendAnalysis';
import { useParams } from 'react-router-dom'
import useTrendsStore from '../state/trend.state';
import { useEffect, useState } from 'react';
import LinkButtonsContainer from '../components/LinkButtonsContainer';


const TrendDescription = () => {

    let { title, id } = useParams()
    console.log({ title, id })
    const { trends } = useTrendsStore()
    const trend = trends.filter((t) => { return t.id === parseInt(id) })[0]


    var focusAreas;
    var trendClusters;
    var sectors;

    const [focusAreasText, setFocusAreasText] = useState("")
    const [trendClustersText, setTrendClustersText] = useState("")
    const [sectorsText, setSectorsText] = useState("")
    useEffect(() => {
        console.log("trend")
        console.log(trend)
        const initLists = () => {
            focusAreas = trend.focusAreas.map((f) => {
                if (f === 1) {
                    return "Efficiency & Synergy"
                }
                else if (f === 2) {
                    return 'Customer Experience';
                }
                else if (f === 3) {
                    return 'Employee Welfare & Future of Work';
                }
                else if (f === 4) {
                    return 'Agility & Resilience';
                }
                else if (f === 5) {
                    return 'ESG & Sustainability';
                }
                else if (f === 6) {
                    return 'Digital/Cyber/Data Security';
                }
            })
            trendClusters = trend.trendClusters.map((f) => {
                if (f === 1) {
                    return "Hyper Connectivity"
                }
                else if (f === 2) {
                    return 'AI & Big Data';
                }
                else if (f === 3) {
                    return 'Cloud & Computing';
                }
                else if (f === 4) {
                    return 'Immersive Reality';
                }
                else if (f === 5) {
                    return 'Web3.0 & Blockchain';
                }
                else if (f === 6) {
                    return 'Physical & Digital Security & Privacy';
                }
                else if (f === 7) {
                    return 'IOT & Next-Gen Industrialization';
                }
                else if (f === 8) {
                    return 'Social Trends';
                }
            })
            sectors = trend.sectors.map((f) => {
                if (f === 1) {
                    return "Property Development & Sales"
                }
                else if (f === 2) {
                    return 'Aldar Projects';
                }
                else if (f === 3) {
                    return 'Investment Properties';
                }
                else if (f === 4) {
                    return 'Hospitality & Leisure';
                }
                else if (f === 5) {
                    return 'Education';
                }
                else if (f === 6) {
                    return 'Aldar Estates';
                }

            })
            focusAreas = focusAreas.join(' , ')
            trendClusters = trendClusters.join(' , ')
            sectors = sectors.join(' , ')

            console.log(trend)

            setFocusAreasText(focusAreas)
            setTrendClustersText(trendClusters)
            setSectorsText(sectors)

        }
        if (trend) {
            initLists()
        }
    }, [trend])
    return <div id='trend' className="flex flex-col items-center w-full h-full">
        {
            trend && <div className="flex flex-col lg:max-w-[1350px] w-full lg:px-7 lg:ml-4">
                <ImageAndTitle title={title} image={trend.imageURL} />
                <LinkButtonsContainer/>
                <div className="flex pt-5 md:flex-row flex-col-reverse justify-center md:p-16">
                    <TrendOverview value={trend.value} timeHorizon={trend.timeHorizon} complexity={trend.complexity} impact={trend.impact} />
                    <Description firstParagraph={trend.firstParagraph} secondParagraph={trend.secondParagraph} thirdParagraph={trend.thirdParagraph} />
                </div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-center items-start gap-2 px-10 py-16 bg-secondary mt-5">
                    <Section title={'Focus Areas'} imageSource={focus_area} text={focusAreasText} />
                    <Section title={'Trend Clusters'} imageSource={trend_clusters} text={trendClustersText} />
                    <Section title={'Sectors'} imageSource={sectors_image} text={sectorsText} />
                </div>

                <RelevanceToTheFuture readinessImageUrl={trend.readinessImageUrl} useCaseImageUrl={trend.useCaseImageUrl} relevanceList={trend.relevanceToTheFutureOfRealEstate} isFavorite={trend.isFavorite}/>

                <TrendAnalysis
                    risksList={trend.risksAndChallenge} outlook={[
                        trend.outlook
                    ]}

                />

            </div>
        }

    </div>
}



export default TrendDescription;