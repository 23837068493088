const ProgressIndicator = ({ title, list, label, color }) => {
    return <div className="flex flex-col">
        <h2 className="font-bold pt-7 pb-3">
            {title}
        </h2>
        <div className="flex gap-2">
            {
                list.map((index) => {
                    return <div className={`flex rounded-md w-10 h-6
                    ${(title === "Impact" && (label >= index)) ? `bg-[#61B1E6]` : ``} border-2 ${title === 'Impact' ? 'border-[#61B1E6]' : ``}
                    ${(title === "Time Horizon" && (label >= index)) ? `bg-[#E55401]` : ``} border-2 ${title === 'Time Horizon' ? 'border-[#E55401]' : ``}
                    ${(title === "Complexity" && (label >= index)) ? `bg-[#FECD02]` : ``} border-2 ${title === 'Complexity' ? 'border-[#FECD02]' : ``}
                    `}>
                        {(index === 1&&title!=="Time Horizon") &&
                            <div className="flex items-center justify-center">
                                <p className="text-xs pl-2">
                                    Low
                                </p>
                            </div>
                        }
                        {(index === 5&&title!=="Time Horizon") &&
                            <div className="flex items-center justify-center">
                                <p className="text-xs pl-2">
                                    High
                                </p>
                            </div>
                        }
                        {(index === 1&&title==="Time Horizon") &&
                            <div className="flex items-center justify-center">
                                <p className="text-xs pl-2">
                                    1 Yr
                                </p>
                            </div>
                        }
                        {(index === 5&&title==="Time Horizon") &&
                            <div className="flex items-center justify-center">
                                <p className="text-xs pl-2">
                                    5 Yr
                                </p>
                            </div>
                        }
                    </div>
                })
            }

        </div>
    </div>
};


const TrendOverview = ({ value, impact, timeHorizon, complexity }) => {
    const valueLabel = value;
    const impactLabel = impact;
    const timeHorizonLabel = timeHorizon;
    const complexityLabel = complexity;
    console.log({
        valueLabel, impactLabel, timeHorizonLabel, complexityLabel
    })
    return <div className="flex flex-col items-center md:items-start md:pt-0 pt-6">
        <h1 className="text-2xl md:text-3xl font-bold ">
            Trend Overview
        </h1>
        <h2 className="font-bold pt-7 pb-3">
            Value
        </h2>
        <div className="flex space-x-5 justify-start">
            {
                ['Low', 'Mid', 'High'].map((title) => {
                    return <div className={`flex rounded-full w-[60px] h-[60px] items-center justify-center ${(valueLabel === 1 && title === 'Low') || (valueLabel === 2 && title === 'Mid') || (valueLabel === 3 && title === 'High') ? ` bg-dark_blue` : `bg-secondary`}`}>
                        <h2 className={`${(valueLabel === 1 && title === 'Low') || (valueLabel === 2 && title === 'Mid') || (valueLabel === 3 && title === 'High') ? `text-white` : `text-[#2C2A29]`}`}>
                            {title}
                        </h2>
                    </div>
                })
            }

        </div>

        <ProgressIndicator title='Impact' list={[1, 2, 3, 4, 5]} label={impactLabel} />
        <ProgressIndicator title='Time Horizon' list={[1, 2, 3, 4, 5]} label={timeHorizonLabel} />
        <ProgressIndicator title='Complexity' list={[1, 2, 3, 4, 5]} label={complexityLabel} />

    </div>

}

export default TrendOverview;