import useSwitchButtonStore from '../state/swich.button.state';
import useTrendsStore from '../state/trend.state';
import ExpandedItem from './ExpandedItem';

var focusAreaSwitchButtonList = [];
var trendsClustersSwitchButtonList = [];
var sectorsSwitchButtonList = [];

const ExpandedList = ({ expandedFilter }) => {
  const { trends, setTrends } = useTrendsStore();
  const { setSwitchButton } = useSwitchButtonStore();

  const filterFunction = () => {
    var index = 0;
    let isInFocusArea = false;
    let isInTrendClusters = false;
    let isInSectors = false;
    for (var __ in trends) {
      isInFocusArea = true;
      isInTrendClusters = true;
      isInSectors = true;
      // filters are not enabled
      if (
        focusAreaSwitchButtonList.length === 0 &&
        trendsClustersSwitchButtonList.length === 0 &&
        sectorsSwitchButtonList.length === 0
      ) {
        trends[index].isSelectedForFilter = false;
        isInFocusArea = false;
        isInTrendClusters = false;
        isInSectors = false;
      } else {
        if (focusAreaSwitchButtonList.length > 0) {
          isInFocusArea = false;
          focusAreaSwitchButtonList.forEach((item) => {
            if (trends[index].focusAreas.includes(item)) {
              isInFocusArea = true;
            }
          });
        }
        if (trendsClustersSwitchButtonList.length > 0) {
          isInTrendClusters = false;
          trendsClustersSwitchButtonList.forEach((item) => {
            if (trends[index].trendClusters.includes(item)) {
              isInTrendClusters = true;
            }
          });
        }
        if (sectorsSwitchButtonList.length > 0) {
          isInSectors = false;
          sectorsSwitchButtonList.forEach((item) => {
            if (trends[index].sectors.includes(item)) {
              isInSectors = true;
            }
          });
        }
      }
      trends[index].isSelectedForFilter = isInFocusArea && isInTrendClusters && isInSectors;
      index++;
    }
    setSwitchButton(
      focusAreaSwitchButtonList.length + trendsClustersSwitchButtonList.length + sectorsSwitchButtonList.length
    );
  };
  const focusAreas = [
    {
      title: 'Efficiency & Synergy',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(1);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 1);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Customer Experience',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(2);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 2);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Employee Welfare & Future of Work',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(3);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 3);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Agility & Resilience',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(4);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 4);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'ESG & Sustainability',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(5);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 5);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Digital/Cyber/Data Security',
      onClick: (value) => {
        if (value) {
          focusAreaSwitchButtonList.push(6);
        } else {
          focusAreaSwitchButtonList = focusAreaSwitchButtonList.filter((item) => item !== 6);
        }
        filterFunction();
        setTrends(trends);
      },
    },
  ];
  const trendClusters = [
    {
      title: 'Hyper Connectivity',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(1);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 1);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'AI & Big Data',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(2);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 2);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Cloud & Computing',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(3);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 3);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Immersive Reality',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(4);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 4);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Web3.0 & Blockchain',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(5);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 5);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Physical & Digital Security & Privacy',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(6);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 6);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'IOT & Next-Gen Industrialization',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(7);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 7);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Social Trends',
      onClick: (value) => {
        if (value) {
          trendsClustersSwitchButtonList.push(8);
        } else {
          trendsClustersSwitchButtonList = trendsClustersSwitchButtonList.filter((item) => item !== 8);
        }
        filterFunction();
        setTrends(trends);
      },
    },
  ];
  const sectors = [
    {
      title: 'Property Development & Sales',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(1);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 1);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Aldar Projects',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(2);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 2);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Investment Properties',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(3);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 3);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Hospitality & Leisure',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(4);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 4);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Education',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(5);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 5);
        }
        filterFunction();
        setTrends(trends);
      },
    },
    {
      title: 'Aldar Estates',
      onClick: (value) => {
        if (value) {
          sectorsSwitchButtonList.push(6);
        } else {
          sectorsSwitchButtonList = sectorsSwitchButtonList.filter((item) => item !== 6);
        }
        filterFunction();
        setTrends(trends);
      },
    },
  ];
  return (
    <ul>
      <li>
        <ExpandedItem title={'Focus Area'} expandedFilter={expandedFilter} items={focusAreas} />
      </li>
      <li>
        <ExpandedItem title={'Trends Clusters'} expandedFilter={expandedFilter} items={trendClusters} />
      </li>
      <li>
        <ExpandedItem title={'Sectors'} expandedFilter={expandedFilter} items={sectors} />
      </li>
    </ul>
  );
};

export default ExpandedList;
