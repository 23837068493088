import radar from '../images/radar.png';
import React, { useRef, useEffect, useState } from "react";
import useTrendsStore from '../state/trend.state';
import usePositionStore from '../state/position.state';

const RadarForAddAndUpdate = ({ isAdd, id }) => {
    const ref = useRef(null);
    const trendReF = useRef(null);
    const radarReF = useRef(null);

    const [widthOfRadar, setWidthOfRadar] = useState(0);
    const [heightOfRadar, setHeightOfRadar] = useState(0);
    const { trends } = useTrendsStore();
    const { x, y, setX, setY } = usePositionStore();
    const trend = trends.filter((t) => { return t.id === parseInt(id) })[0]

    useEffect(() => {
        if (trend&&!isAdd) {
            setX(trend.x)
            setY(trend.y)
        }
    }, [trend])

    const getPosition = event => {
        var ele = document.getElementById('img-radar');
        var offset = ele.getBoundingClientRect();
        const xx = (event.clientX - offset.left) / 922;
        const yy = (event.clientY - offset.top) / 854;
        setX(xx);
        setY(yy);
    };


    useEffect(() => {
        var ele = document.getElementById('img-radar-container');
        setWidthOfRadar(ele ? ele.clientWidth : 0);
        setHeightOfRadar(ele? ele.clientHeight : 854);

        const handleResize = () => {
            setWidthOfRadar(ref.current ? ref.current.offsetWidth : 0);
            setHeightOfRadar(ref.current ? ref.current.offsetHeight : 0);
        }
        window.addEventListener("resize", handleResize)

    }, [ref.current]);


    return (
        <div className="flex w-full relative min-w-[700px] max-h-[854px] max-w-[922px]" ref={ref} id='img-radar-container'>
            <img src={radar} alt="radar" className='z-10 absolute' ref={radarReF} id='img-radar' onClick={getPosition} />
            {
                (isAdd) &&
                trends.map((trend, index) => {
                    return (
                        <div key={index} className={`flex z-20 absolute ${trend.isSelectedForFilter ? 'bg-gray-50' : ''} items-center justify-start rounded-md px-1`} ref={trendReF} style={{
                            top: trend.y * heightOfRadar, left: trend.x * widthOfRadar
                        }}>
                            <div className={`flex z-20  rounded-full w-2 h-2  items-center justify-center `}
                                style={
                                    {
                                        background: trend.isSocial ?? false ? "#cbda00" : "#61b1e8"
                                    }
                                }
                            >
                                <div className="flex rounded-full w-1 h-1 bg-white">
                                </div>
                            </div>
                            <h1 className='px-[1px] text-xs'>
                                {trend.title}
                            </h1>
                        </div>
                    )
                })
            }
            {
                (!isAdd) &&
                trends.map((trend) => {
                    if (trend.id !== parseInt(id)) {
                        return (
                            <div className={`flex z-20 absolute ${trend.isSelectedForFilter ? 'bg-gray-50' : ''} items-center justify-start rounded-md px-1`} ref={trendReF} style={{
                                top: trend.y * heightOfRadar, left: trend.x * widthOfRadar
                            }}>
                                <div className={`flex z-20  rounded-full w-2 h-2  items-center justify-center `}
                                    style={
                                        {
                                            background: trend.isSocial ?? false ? "#cbda00" : "#61b1e8"
                                        }
                                    }
                                >
                                    <div className="flex rounded-full w-1 h-1 bg-white">
                                    </div>
                                </div>
                                <h1 className='px-[1px] text-xs'>
                                    {trend.title}
                                </h1>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className={`flex z-20 absolute items-center justify-start rounded-md px-`} style={{
                                top: y * heightOfRadar, left: x * widthOfRadar
                            }}>
                                <div className={`flex z-20  rounded-full w-2 h-2 bg-black  items-center justify-center `}

                                >
                                    <div className="flex rounded-full w-1 h-1 bg-white">
                                    </div>
                                </div>
                                <h1 className='px-[1px] text-xs'>
                                    {trend.title}
                                </h1>
                            </div>
                        )
                    }
                })
            }

          {
            (isAdd)&&  <div className={`flex z-20 absolute items-center justify-start rounded-md px-`} style={{
                top: y * heightOfRadar, left: x * widthOfRadar
            }}>
                <div className={`flex z-20  rounded-full w-2 h-2 bg-black  items-center justify-center `}

                >
                    <div className="flex rounded-full w-1 h-1 bg-white">
                    </div>
                </div>
                <h1 className='px-[1px] text-xs'>
                    title
                </h1>
            </div>
          }

        </div>
    );
}

export default RadarForAddAndUpdate;