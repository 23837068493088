import Filter from './Filter';
import Radar from './Radar';
const RadarAndFilter = () => {
  return (
    <div className="flex w-full lg:flex-row flex-col-reverse justify-between items-start ">
      <Filter />
      <div className="flex w-full justify-center items-center">
        <Radar />
      </div>
    </div>
  );
};
export default RadarAndFilter;
